import { DateTime } from 'luxon';
import { useState } from 'react';
import { FaSpinner } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import stringToColor from 'string-to-color';
import api from '../../api';
import { INotification } from '../../api/services/notification.service';
import { useAuth } from '../../hooks/useAuth';
import { Card } from '../shared/Card';
import { IProject } from '../../api/services/project.service';
import StatusBadge from '../shared/StatusBadge';
import {
  BiBell,
  BiEdit,
  BiFolderPlus,
  BiMailSend,
  BiNews,
  BiUpload,
  BiUserMinus,
  BiUserPlus,
} from 'react-icons/bi';

interface Props {
  className?: string;
  activities: INotification[];
}

const ACTIVE_EVENTS = [
  'PROJECT_CREATED',
  'PROJECT_MEMBER_JOINED',
  'PROJECT_MEMBER_REMOVED',
  'PROJECT_STATUS_CHANGED',
  'FILE_CREATED',
  'PROJECT_MEMBER_INVITED_EXTERNAL',
];

export default function ActivitySection({ className, activities }: Props) {
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);

  function getIconForEvent(eventName: string) {
    switch (eventName) {
      case 'PROJECT_STATUS_CHANGED':
        return <BiEdit className='h-6 w-6 text-2xl text-white' />;
      case 'PROJECT_CREATED':
        return <BiFolderPlus className='h-6 w-6 text-2xl text-white ' />;
      case 'FILE_CREATED':
        return <BiUpload className='h-6 w-6 text-2xl text-white ' />;
      case 'PROJECT_MEMBER_INVITED_EXTERNAL':
        return <BiMailSend className='h-6 w-6 text-2xl text-white ' />;
      case 'PROJECT_MEMBER_JOINED':
        return <BiUserPlus className='h-6 w-6 text-2xl text-white ' />;
      case 'PROJECT_MEMBER_REMOVED':
        return <BiUserMinus className='h-6 w-6 text-2xl text-white ' />;
      default:
        return <BiBell className='h-6 w-6 text-2xl text-white ' />;
    }
  }

  function getNotificationMessage(notification: INotification) {
    const { metaData } = notification;
    const project: IProject = metaData['ProjectDTO'];
    const file = metaData['FileDTO'];
    // const fileBase = metaData['FileBaseDTO'];
    const user = metaData['UserBaseDTO'];
    const invitedUser = metaData['ProjectInviteDTO'];

    switch (notification.eventName) {
      case 'FILE_CREATED':
        return (
          <>
            {file && <>uploaded a new file</>}
            {!file && <>uploaded a new file that has since been deleted</>}{' '}
            <Link
              to={`/submission/${project?.id}`}
              className='font-semibold text-indigo-600'
            >
              {file?.name}
            </Link>
          </>
        );
      case 'PROJECT_STATUS_CHANGED':
        if (project.type === 'SUBMISSION') {
          return (
            <div className=''>
              <div>
                changed the status of the submission{' '}
                <Link
                  to={`/submission/${project?.id}`}
                  className='font-semibold text-indigo-600'
                >
                  {project?.name}
                </Link>{' '}
                to <b>{project.status}</b>
              </div>
            </div>
          );
        } else {
          return (
            <div className=''>
              <div>
                changed the status of the album{' '}
                <Link
                  to={`/album/${project?.id}`}
                  className='font-semibold text-indigo-600'
                >
                  {project?.name}
                </Link>{' '}
                to <b>{project.status}</b>
              </div>
            </div>
          );
        }
      case 'PROJECT_CREATED':
        if (project.type === 'SUBMISSION') {
          return (
            <div>
              created the new submission{' '}
              <Link
                to={`/submission/${project?.id}`}
                className='font-semibold text-indigo-600'
              >
                {project?.name}
              </Link>
            </div>
          );
        } else {
          return (
            <div>
              created the new album{' '}
              <Link
                to={`/album/${project?.id}`}
                className='font-semibold text-indigo-600'
              >
                {project?.name}
              </Link>
            </div>
          );
        }
      case 'PROJECT_MEMBER_JOINED':
        if (project.type === 'SUBMISSION') {
          return (
            <>
              joined the submission{' '}
              <Link
                to={`/submission/${project?.id}`}
                className='font-semibold text-indigo-600'
              >
                {project?.name}
              </Link>
            </>
          );
        }
        return notification.eventName;
      case 'PROJECT_MEMBER_INVITED_EXTERNAL':
        if (project.type === 'SUBMISSION') {
          return (
            <>
              invited <b>{invitedUser.targetUserEmail}</b> to submit in{' '}
              <Link
                to={`/submission/${project?.id}`}
                className='font-semibold text-indigo-600'
              >
                {project?.name}
              </Link>
            </>
          );
        }
        return notification.eventName;
      case 'PROJECT_MEMBER_REMOVED':
        return (
          <>
            removed {user?.firstname} {user?.lastname} from the project{' '}
            <Link
              to={`/submission/${project?.id}`}
              className='font-semibold text-indigo-600'
            >
              {project?.name}
            </Link>
          </>
        );
      default:
        return notification.eventName;
    }
  }

  function NotificationRow({ notification }: { notification: INotification }) {
    function getTargetUser(notification: INotification) {
      if (notification.eventName === 'PROJECT_MEMBER_JOINED') {
        return notification.metaData['UserBaseDTO'];
      }
      return notification.metaData['actor'];
    }

    function selfWrapper(userObj: any) {
      if (userObj && userObj['id'] === user?.id) {
        userObj['firstname'] = 'You';
        userObj['lastname'] = null;
      }
      return userObj;
    }

    const targetUser = selfWrapper(getTargetUser(notification));
    return (
      <div
        key={notification.id}
        className='relative flex h-24 overflow-hidden break-all rounded-lg bg-white py-2 px-4 shadow hover:cursor-pointer hover:bg-slate-50 dark:bg-slate-900 hover:dark:bg-slate-800 md:h-20 lg:h-24 xl:h-16'
      >
        <div className='flex w-full items-center space-x-4'>
          <div className='flex rounded-md bg-indigo-500 p-2'>
            {getIconForEvent(notification.eventName)}
          </div>
          <div className='flex-1 space-y-1'>
            <div className='flex items-center justify-between'>
              {targetUser && (
                <h3 className='text-sm font-medium'>{`${
                  targetUser['firstname']
                }${
                  targetUser['lastname'] ? ' ' + targetUser['lastname'] : ''
                }`}</h3>
              )}
              {!targetUser && (
                <h3 className='text-sm font-medium'>{notification.actorId}</h3>
              )}
              <p className='text-sm text-gray-500'>
                {DateTime.fromJSDate(
                  new Date(notification.createdAt)
                ).toRelative()}
              </p>
            </div>
            <div className='break-all text-sm text-gray-500'>
              {getNotificationMessage(notification)}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className='flex flex-col space-y-5'>
      <div className='flex items-center space-x-2'>
        <BiNews className='text-2xl text-indigo-500' />
        <div className='text-lg font-semibold'>Recent Activity</div>
      </div>

      <dl className='flex h-80 w-full flex-col overflow-scroll'>
        {loading && (
          <div className='flex items-center justify-center p-20 text-slate-500'>
            <FaSpinner size={30} className='animate-spin' />
          </div>
        )}
        {!loading && activities.length > 0 && (
          <div className='flex flex-col space-y-4'>
            {activities
              .filter((a) => {
                if (ACTIVE_EVENTS.indexOf(a.eventName) > -1) {
                  // don't show when someone joins an ALBUM
                  if (
                    a.eventName === 'PROJECT_MEMBER_JOINED' &&
                    a.metaData['ProjectDTO'] &&
                    a.metaData['ProjectDTO']?.type === 'ALBUM'
                  ) {
                    return false;
                  }
                  // don't show if join event of label member
                  if (
                    a.eventName === 'PROJECT_MEMBER_JOINED' &&
                    (a.metaData['ProjectMembershipDTO']?.role === 'MEMBER' ||
                      a.metaData['ProjectMembershipDTO']?.role === 'OWNER')
                  ) {
                    return false;
                  }
                  // don't show info about deleted project
                  if (
                    a.eventName === 'PROJECT_MEMBER_JOINED' &&
                    !a.metaData['ProjectDTO']
                  ) {
                    return false;
                  }
                  return true;
                }
                return false;
              })
              .map((notification, i) => (
                <NotificationRow key={i} notification={notification} />
              ))}
            {activities.length < 5 && (
              <div className='flex items-center justify-center text-slate-500'>
                No previous events available at this time.
              </div>
            )}
          </div>
        )}
        {!loading && activities.length === 0 && (
          <div className='flex flex-col items-center justify-center p-8 text-slate-500'>
            <div className='mb-2 font-semibold text-slate-700'>
              No Activities yet
            </div>
            <div className='inline-flex items-center space-x-2'>
              <div>
                Start using the platform and you will see the most recent
                project activity here
              </div>
            </div>
          </div>
        )}
      </dl>
    </div>
  );
}
