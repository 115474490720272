import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../../api';
import { IAuthenticatedUser } from '../../hooks/useAuth';
import BasicSelect from '../shared/BasicSelect';

interface KeyValue {
  key: string;
  value: string;
}

const occupations: KeyValue[] = [
  {
    key: '1',
    value: 'Label Manager',
  },
  {
    key: '2',
    value: 'Artist',
  },
  {
    key: '3',
    value: 'Composer',
  },
  {
    key: '4',
    value: 'Producer',
  },
  {
    key: '5',
    value: 'A&R Manager',
  },
  {
    key: '6',
    value: 'Distributor',
  },
  {
    key: '7',
    value: 'Other',
  },
];

const catalogSize: KeyValue[] = [
  {
    key: '1',
    value: 'Less than 100 tracks',
  },
  {
    key: '2',
    value: '100-500 tracks',
  },
  {
    key: '3',
    value: '500-1000 tracks',
  },
  {
    key: '4',
    value: 'More than 1000 tracks',
  },
];

const usageIntentions: KeyValue[] = [
  {
    key: '1',
    value: 'Submission collection',
  },
  {
    key: '2',
    value: 'Music catalog management',
  },
  {
    key: '3',
    value: 'Communicate with artists',
  },
  {
    key: '4',
    value: 'Use AI automation tools',
  },
  {
    key: '5',
    value: 'Something else',
  },
];

export function UserQuestionnaireStepWindow({
  user,
  nextStep,
}: {
  user: IAuthenticatedUser | null;
  nextStep: () => void;
}) {
  const [occupation, setOccupation] = useState<KeyValue | null>(null);
  const [otherOccupation, setOtherOccupation] = useState<string>('');

  const [catalogSizeSelected, setCatalogSizeSelected] =
    useState<KeyValue | null>(null);
  const [usageIntention, setUsageIntention] = useState<KeyValue | null>(null);
  const [otherUsageIntention, setOtherUsageIntention] = useState<string>('');

  const [userHasBeenActive, setUserHasBeenActive] = useState<boolean>(false);
  const [initial, setInitial] = useState<boolean>(true);

  useEffect(() => {
    if (initial) {
      setInitial(false);
    } else {
      console.log('changed');
      setUserHasBeenActive(true);
    }
  }, [occupation, catalogSizeSelected, usageIntention]);

  async function addUserInsight(userId: string) {
    try {
      const response = await api.user.addUserInsight(userId, 'ABOUT', {
        occupation,
        otherOccupation,
        catalogSizeSelected,
        usageIntention,
        otherUsageIntention,
      });
      if (response.status === 201) {
        nextStep();
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <>
      <span className='mt-4 font-medium text-gray-700 dark:text-slate-200'>
        Tell us who you are so we can provide you the best possible experience
      </span>
      <div className='my-5 flex w-full justify-around pb-2 lg:w-4/6'>
        <div className='grid grid-cols-1 gap-5 lg:grid-cols-2'>
          <div className=''>
            <span className='text-sm text-slate-500 dark:text-slate-200'>
              What's your current occupation best?
            </span>
            <div className='mt-2 '>
              <BasicSelect
                options={occupations}
                selected={occupation}
                setSelected={(occ: KeyValue) => setOccupation(occ)}
              />
            </div>
          </div>
          {occupation && occupation.value === 'Other' && (
            <div>
              <span className='text-sm text-slate-500 dark:text-slate-200'>
                We'd love to hear more about your occupation
              </span>
              <div className='mt-2'>
                <input
                  className='relative w-full cursor-default rounded-lg border bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 dark:border-slate-400 dark:bg-slate-600 sm:text-sm'
                  value={otherOccupation}
                  onChange={(event) => setOtherOccupation(event.target.value)}
                />
              </div>
            </div>
          )}
          {occupation &&
            occupation.value !== 'Artist' &&
            occupation.value !== 'Composer' && (
              <div>
                <span className='text-sm text-slate-500 dark:text-slate-200'>
                  What is the size of your music catalog?
                </span>
                <div className='mt-2'>
                  <BasicSelect
                    options={catalogSize}
                    selected={catalogSizeSelected}
                    setSelected={(col: KeyValue) => setCatalogSizeSelected(col)}
                  />
                </div>
              </div>
            )}
          <div>
            <span className='text-sm text-slate-500 dark:text-slate-200'>
              What are you trying to use KTR Studio for?
            </span>
            <div className='mt-2'>
              <BasicSelect
                options={usageIntentions}
                selected={usageIntention}
                setSelected={(col: KeyValue) => setUsageIntention(col)}
              />
            </div>
          </div>
          {usageIntention && usageIntention.value === 'Something else' && (
            <div>
              <span className='text-sm text-slate-500 dark:text-slate-200'>
                We'd love to hear more about your expectations
              </span>
              <div className='mt-2'>
                <input
                  className='relative mt-1 w-full cursor-default rounded-lg border bg-white py-2 pl-3 pr-10 text-left shadow-md focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-indigo-300 sm:text-sm'
                  value={otherUsageIntention}
                  onChange={(event) =>
                    setOtherUsageIntention(event.target.value)
                  }
                />
              </div>
            </div>
          )}
          <div className='mt-8'>
            {user && (
              <button
                disabled={!userHasBeenActive}
                type='submit'
                className='group relative flex w-full justify-center rounded-md border border-transparent bg-indigo-600 py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-100 disabled:text-gray-500'
                onClick={() => addUserInsight(user.id)}
              >
                Confirm
              </button>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
