import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import api from '../api';
import {
  allElements,
  allInstruments,
  allKeywords,
  allMoods,
  allMusicFor,
  allStyles,
} from '../components/studio/sections/trackInfo/tagOptions';
import { useAuth } from '../hooks/useAuth';

export default function TagsRoute() {
  const [searchParams] = useSearchParams();
  const { activeTeam } = useAuth();

  useEffect(() => {
    const loadTags = searchParams.get('loadTags');
    if (loadTags && activeTeam) {
      addAllElements(activeTeam.id);
      addAllInstruments(activeTeam.id);
      addAllMusicFor(activeTeam.id);
      addAllMoods(activeTeam.id);
      addAllKeywords(activeTeam.id);
      addAllStyles(activeTeam.id);
    }
  }, [searchParams, activeTeam]);

  // async function addCountries(teamId: string) {
  //   await Promise.all([
  //     countries.map((country) =>
  //       api.tag.addTag({
  //         name: country,
  //         teamId: teamId,
  //         type: "country",
  //       })
  //     ),
  //   ]);
  // }

  async function addAllElements(teamId: string) {
    await Promise.all([
      allElements.map((element) =>
        api.tag.addTag({
          name: element,
          teamId: teamId,
          type: 'element'.toUpperCase(),
        })
      ),
    ]);
  }

  async function addAllInstruments(teamId: string) {
    await Promise.all([
      allInstruments.map((instrument) =>
        api.tag.addTag({
          name: instrument,
          teamId: teamId,
          type: 'instrument'.toUpperCase(),
        })
      ),
    ]);
  }

  async function addAllMusicFor(teamId: string) {
    await Promise.all([
      allMusicFor.map((musicFor) =>
        api.tag.addTag({
          name: musicFor,
          teamId: teamId,
          type: 'musicFor'.toUpperCase(),
        })
      ),
    ]);
  }

  async function addAllMoods(teamId: string) {
    await Promise.all([
      allMoods.map((mood) =>
        api.tag.addTag({
          name: mood,
          teamId: teamId,
          type: 'mood'.toUpperCase(),
        })
      ),
    ]);
  }

  async function addAllKeywords(teamId: string) {
    await Promise.all([
      allKeywords.map((keyword) =>
        api.tag.addTag({
          name: keyword,
          teamId: teamId,
          type: 'keyword'.toUpperCase(),
        })
      ),
    ]);
  }
  async function addAllStyles(teamId: string) {
    await Promise.all([
      allStyles.map((style) =>
        api.tag.addTag({
          name: style,
          teamId: teamId,
          type: 'style'.toUpperCase(),
        })
      ),
    ]);
  }
  return <></>;
}
