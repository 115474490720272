import { useEffect, useState } from 'react';
import { IComposer } from '../../../../api/services/composer.service';
import { FaEye, FaCheck } from 'react-icons/fa';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import Input from '../../../shared/Input';
import { toast } from 'react-toastify';
import InfoTooltip from '../../../shared/InfoTooltip';
import { ContractStatusBadge } from '../../../shared/ContractStatusBadge';
import { Contract } from '../../../../api/services/contract.service';

interface Props {
  composer: IComposer;
  handleOpenModal: (c: IComposer) => void;
  createdByUser: boolean;
  canBeModified: boolean;
  handleDelete: (c: IComposer) => void;
  editableRoyalty: boolean;
  onConfirmComposerRoyaltyChange: (
    id: string,
    royaltyShare: number
  ) => Promise<void>;
  validate: (v: number) => boolean;
  index: number;
  contracts?: Contract[];
}

export default function ComposerRow({
  composer,
  handleOpenModal,
  createdByUser,
  canBeModified,
  handleDelete,
  editableRoyalty,
  onConfirmComposerRoyaltyChange,
  validate,
  index,
  contracts,
}: Props) {
  const [isEditing, setIsEditing] = useState(false);
  const [royalty, setRoyalty] = useState(0);

  useEffect(() => {
    setRoyalty(composer.royaltyShare);
  }, [composer]);

  useEffect(() => {
    if (!editableRoyalty) {
      setIsEditing(editableRoyalty);
    }
  }, [editableRoyalty]);

  const onSubmitRoyalty = async () => {
    if (royalty % 2 !== 0) {
      return toast.error('Please enter an even number as royalty share only.');
    }
    if (!validate(royalty)) {
      setRoyalty(composer.royaltyShare);
      return toast.error(
        'Sum of the royalty shares of all composers should be lower than 100'
      );
    }
    await onConfirmComposerRoyaltyChange(composer.id, royalty);
    setIsEditing(false);
  };

  return (
    <li className='py-4' key={composer.id}>
      <div className='flex items-start space-x-4'>
        <div className='flex-shrink-0'>
          <span className='inline-block h-14 w-14 overflow-hidden rounded-full bg-gray-100'>
            <svg
              className='h-full w-full text-gray-300'
              fill='currentColor'
              viewBox='0 0 24 24'
            >
              <path d='M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z' />
            </svg>
          </span>
        </div>
        <div className='min-w-0 flex-1'>
          <p className='truncate text-sm font-medium text-gray-900 dark:text-gray-100'>
            {composer.firstName} {composer.lastName}
          </p>
          <div className='mt-2 flex flex-wrap gap-x-4'>
            <div className='space-y-1'>
              <p className='truncate text-sm text-gray-500'>
                IPI: {composer.ipi}
              </p>
              <p className='truncate text-sm text-gray-500'>
                PRO: {composer.pro}
              </p>
            </div>
            <div className='space-y-1'>
              <div
                className='flex items-center text-sm text-gray-500'
                id={`composer-${index}-royalty`}
              >
                Royalty Share :&nbsp;
                {isEditing ? (
                  <>
                    <Input
                      className='input-no-arrows ml-4 !mt-0 !w-10 !p-2 text-center text-black'
                      type='number'
                      value={royalty}
                      onChange={(e) => {
                        setRoyalty(+e.target.value);
                      }}
                      max={100}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          onSubmitRoyalty();
                        }
                      }}
                    />
                    <button
                      className='ml-3 mr-2 dark:bg-white dark:px-px'
                      onClick={onSubmitRoyalty}
                    >
                      <FaCheck className='fill-gray-900' />
                    </button>
                  </>
                ) : (
                  <>
                    {royalty} %
                    {editableRoyalty && (
                      <button
                        onClick={() => setIsEditing(true)}
                        className='mx-2'
                      >
                        <HiOutlinePencilSquare className='h-4 w-4 stroke-indigo-500' />
                      </button>
                    )}
                  </>
                )}
                <InfoTooltip hint='Here you can enter the share freely. Only whole numbers, no decimal numbers. Total shares for all composers must be 100%.' />
              </div>
              <div className='space-y-1'>
                {(contracts || []).map((c) => (
                  <div
                    className='flex items-center text-sm text-gray-500'
                    key={c.contractId}
                  >
                    Contract status&nbsp;
                    <ContractStatusBadge status={c.status} />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className='flex flex-row items-center'>
          {createdByUser && (
            <div
              onClick={() => handleOpenModal(composer)}
              className='inline-flex cursor-pointer items-center px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 hover:scale-110'
            >
              <HiOutlinePencilSquare className='h-7 w-7 stroke-indigo-500' />
            </div>
          )}
          {!createdByUser && (
            <div
              onClick={(e) => handleOpenModal(composer)}
              className='inline-flex cursor-pointer items-center px-2.5 py-0.5 text-sm font-medium leading-5 text-gray-700 hover:scale-110'
            >
              <FaEye className='h-7 w-7 text-indigo-500' />
            </div>
          )}
          {canBeModified && (
            <div
              className='mx-5 flex cursor-pointer justify-end'
              onClick={() => handleDelete(composer)}
            >
              <svg
                strokeWidth='0'
                viewBox='0 0 448 512'
                className='h-6 w-6 fill-indigo-500 hover:scale-110'
                aria-hidden='true'
                height='1em'
                width='1em'
                xmlns='http://www.w3.org/2000/svg'
              >
                <path d='M432 32H312l-9.4-18.7A24 24 0 0 0 281.1 0H166.8a23.72 23.72 0 0 0-21.4 13.3L136 32H16A16 16 0 0 0 0 48v32a16 16 0 0 0 16 16h416a16 16 0 0 0 16-16V48a16 16 0 0 0-16-16zM53.2 467a48 48 0 0 0 47.9 45h245.8a48 48 0 0 0 47.9-45L416 128H32z'></path>
              </svg>
            </div>
          )}
        </div>
      </div>
    </li>
  );
}
