import axios from 'axios';
import { FaExclamationTriangle, FaFileDownload, FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import api from '../../../../api';
import { IFile } from '../../../../api/services/file.service';
import { IProject } from '../../../../api/services/project.service';
import { IStateProp } from '../../studioState';
import { FileInUpload } from './FileTabWindow.helpers';
import { useEffect, useState } from 'react';
import Loader from '../../../shared/Loader';
import InfoTooltip from '../../../shared/InfoTooltip';
import DeleteConfirmationDialog from '../../../shared/DeleteConfirmationDialog';
import useDialog from '../../../../hooks/useDialog';

export type SpecialFieldType = 'raw-file' | 'stem-archive' | 'master-file';

export interface IFileInputField {
  id?: SpecialFieldType;
  name: string;
  targetContentTypes: {
    [key: string]: string[];
  };
  targetRole: string;
  canBeModified: boolean;
  icon: JSX.Element;
  description?: JSX.Element;
  authorize?: (userTenantRole: String, userProjectRole: String) => boolean;
  hint?: string;
}

export function FileUploadField({
  fileField,
  activeFile,
  refreshData,
  description,
  canBeModified,
  id,
  fileInUpload,
  onChange,
}: {
  fileField: IFileInputField;
  files: IFile[];
  trackId: string;
  activeProject: IProject;
  refreshData: (state: IStateProp) => void;
  description?: JSX.Element;
  canBeModified: boolean;
  id?: string;
  fileInUpload?: FileInUpload;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  activeFile?: IFile | null;
}) {
  const [isDownloading, setIsDownloading] = useState(false);
  const { closeDialog, isDialogOpened, openDialog } = useDialog();
  const [error, setError] = useState(false);

  useEffect(() => {
    if (activeFile && activeFile.status === 'IN_UPLOAD' && !fileInUpload) {
      setError(true);
    } else {
      setError(false);
    }
  }, [activeFile]);

  async function deleteFile(file: IFile) {
    try {
      await api.file.deleteFile(file.id);
      refreshData('activeFiles');
      refreshData('activeProjectTracks');
      closeDialog();
      toast.success('File deleted');
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function downloadFile(file: IFile) {
    setIsDownloading(true);
    api.file
      .getFilePresignedDownloadUrl(file.id)
      .then((res) => {
        const downloadUrl = res.data.result.presignedUrl;
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.download = file.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        setIsDownloading(false);
      })
      .catch((e) => {
        setIsDownloading(false);
        if (axios.isAxiosError(e)) {
          if (e.response?.data) {
            toast.error(e.response?.data.errorMessage);
          }
        } else {
          console.log(e);
          toast.error(
            'Something really went wrong, you might want to contact support!'
          );
        }
      });
  }

  return (
    <div className='flex w-full flex-col'>
      <DeleteConfirmationDialog
        isLoading={false}
        isOpen={isDialogOpened}
        title={`Delete file `}
        message={
          <>
            Do you really want to delete the file <b>{activeFile?.name}</b>?
            This action is irreversible.
          </>
        }
        close={closeDialog}
        onSubmit={() => activeFile && deleteFile(activeFile)}
      />
      <label className='block text-lg font-medium '>{fileField.name}</label>
      <div className='mt-3 mb-3 flex items-center'>
        <label
          className={` flex w-96
            flex-row items-center rounded-md border border-gray-300 p-1 px-2 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
              canBeModified && !activeFile ? 'cursor-pointer' : ''
            }`}
          style={{ height: '38px' }}
          id={id}
        >
          <div className='flex-shrink-0'>
            {error ? (
              <FaExclamationTriangle className='h-6 w-6 text-amber-500' />
            ) : (
              fileField.icon
            )}
          </div>
          <div className='ml-2 w-full'>
            {fileInUpload && !activeFile && (
              <>
                <div>
                  <span className='text-sm font-medium'>
                    {`${fileInUpload.progress}%`}
                  </span>
                </div>
                <div className='h-5 w-full flex-col justify-end break-all text-sm font-semibold text-gray-400'>
                  <div className='h-2.5 w-full rounded-full bg-gray-700 dark:bg-gray-300'>
                    <div
                      className='h-2.5 rounded-full bg-emerald-600'
                      style={{
                        width: `${fileInUpload.progress + 1}%`,
                      }}
                    ></div>
                  </div>
                </div>
              </>
            )}
            {activeFile && (
              <div
                className={`font-medium ${error ? 'text-amber-500' : ''}`}
                data-testid={fileField.id + '-active-file'}
              >
                {error
                  ? 'Error during upload, please delete and try again'
                  : activeFile && activeFile.name}
              </div>
            )}
            {!activeFile && !fileInUpload && (
              <div className='font-medium'>
                {canBeModified && (
                  <span className='text-sm text-gray-500'>
                    Click to upload {fileField.targetContentTypes[0]} file
                  </span>
                )}
                {!canBeModified && (
                  <span className='text-sm text-gray-500'>
                    No file uploaded
                  </span>
                )}
                <input
                  accept={Object.entries(fileField.targetContentTypes)
                    .flat()
                    .join(', ')}
                  className='hidden'
                  onChange={onChange}
                  type='file'
                  data-testid={fileField.id + '-input'}
                />
              </div>
            )}
          </div>
        </label>
        {!activeFile && fileField.hint && (
          <div className='pl-3'>
            <InfoTooltip
              hint={fileField.hint}
              className='!whitespace-pre-line'
            />
          </div>
        )}
        {activeFile && !error && (
          <>
            {isDownloading ? (
              <div className='flex h-5 w-10 items-center justify-start pl-3'>
                <Loader className='!static !h-4 !w-4 bg-transparent !text-indigo-700 ' />
              </div>
            ) : (
              <button
                className='group flex items-center rounded-md pl-3 text-sm'
                onClick={() => downloadFile(activeFile)}
              >
                <FaFileDownload
                  className='mr-2 h-5 w-5 hover:scale-110'
                  aria-hidden='true'
                />
              </button>
            )}
          </>
        )}
        {activeFile && canBeModified && (
          <button
            className='group flex items-center rounded-md pl-3 text-sm'
            onClick={openDialog}
          >
            <FaTrash
              className='mr-2 h-5 w-5 hover:scale-110'
              aria-hidden='true'
            />
          </button>
        )}
      </div>
      {description && (
        <div className='ml-3 text-sm font-medium'>{description}</div>
      )}
    </div>
  );
}
