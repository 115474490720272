import { FaUpload, FaUserClock } from 'react-icons/fa';
import { IProject } from '../../../api/services/project.service';
import { ITrack } from '../../../api/services/track.service';
import { globalThemeStyles } from '../../../config/globalStyles';
import { useDropzoneState } from '../StudioDropzoneWrapper';
import { DropzoneWrapper } from '../DropzoneWrapper';

export function StudioDropzone({
  activeProjectTracks,
  activeProject,
  activeProjectRole,
}: {
  activeProjectTracks: ITrack[];
  activeProject: IProject | null;
  activeProjectRole: string | null;
}) {
  const { openFileDialog, isDragActive, acceptedFiles, rootProps, inputProps } =
    useDropzoneState();

  return (
    <>
      {acceptedFiles.length === 0 &&
        activeProjectTracks.length === 0 &&
        activeProject &&
        activeProject.status !== 'DRAFT' && (
          <DropzoneWrapper
            rootProps={rootProps}
            inputProps={inputProps}
            testid='StudioDropzone-input'
          >
            <div
              onClick={() => openFileDialog()}
              className={`flex grow cursor-pointer flex-col items-center justify-center rounded-2xl font-bold text-gray-800 shadow-xl ${
                isDragActive
                  ? 'border-2 border-dashed bg-blue-300'
                  : globalThemeStyles.box
              }`}
            >
              <div>
                {activeProjectRole === 'GUEST' && (
                  <div className='flex flex-col items-center justify-center space-y-2'>
                    <FaUpload size={30} />
                    <p>
                      Drag and drop to upload files or just click here to select
                      them
                    </p>
                    <div className='px-6 pt-2 font-normal'>
                      <p className='mb-2'>Requirements:</p>
                      <ul className='list-inside list-disc space-y-1'>
                        <li>
                          Please upload your UNMASTERED WAVE files in 24 Bit and
                          48 Khz, bouncing at max. -6db headroom in peaks (RMS)
                        </li>
                        <li>No compression on the final mix / stereo sum!</li>
                        <li>
                          Be aware to not cut the tails at the end of your
                          track, No fade-outs!
                        </li>
                        <li>
                          Be aware of clicks, clips and other artifacts that can
                          result from rough cuts / lack of micro fades
                        </li>
                      </ul>
                    </div>
                  </div>
                )}
                {activeProjectRole !== 'GUEST' && (
                  <div className='flex flex-col items-center justify-center space-y-4'>
                    <FaUserClock size={30} />
                    <div>
                      Your submission request has been sent to the artist.
                    </div>
                    <div>
                      Once the artist starts uploading files, you can see the
                      progress here.
                    </div>
                    <div className='px-6 pt-2 font-normal'>
                      You can also start to upload files by clicking here or via
                      drag and drop
                    </div>
                  </div>
                )}
              </div>
            </div>
          </DropzoneWrapper>
        )}
    </>
  );
}
