import { ChangeEventHandler, useEffect, useRef, useState } from 'react';
import { globalThemeStyles } from '../../../../config/globalStyles';
import useDebounce from '../../../../hooks/useDebounce';
import ReactDatePicker from 'react-datepicker';
import { useAlbumStudioState } from '../../albumState';
import { DescriptionsDialog } from '../../dialogs/DescriptionsDialog';
import ArtworkUploader from '../ArtworkUploader';
import InlineTextAreaEdit from '../../../shared/InlineTextareaEdit';

export function GeneralTabWindowAlbum() {
  const { updateAlbum, activeAlbum, activeActiveAlbumTags } =
    useAlbumStudioState();
  const mounted = useRef(false);
  const [isOpen, setIsOpen] = useState(false);

  const [name, setName] = useState(activeAlbum?.name || '');
  const [subtitle, setSubtitle] = useState(
    activeAlbum?.metadata?.subtitle || ''
  );
  const [description, setDescription] = useState(
    activeAlbum?.description || ''
  );
  const [albumCode, setAlbumCode] = useState(
    activeAlbum?.metadata?.albumCode || ''
  );
  const [releaseDate, setReleaseDate] = useState<Date | null>(
    activeAlbum?.metadata?.releaseDate
      ? new Date(activeAlbum?.metadata?.releaseDate)
      : null
  );

  const debouncedName = useDebounce(name, 300);
  const debouncedAlbumCode = useDebounce(albumCode, 300);
  const debouncedDescription = useDebounce(description, 300);
  const debouncedReleaseDate = useDebounce(releaseDate, 300);
  const debouncedSubtitle = useDebounce(subtitle, 300);

  useEffect(() => {
    if (mounted.current && activeAlbum) {
      updateAlbum({
        name: debouncedName,
        description: debouncedDescription,
        metadata: {
          ...(activeAlbum.metadata || {}),
          albumCode: debouncedAlbumCode,
          subtitle: debouncedSubtitle,
          releaseDate: releaseDate ? releaseDate.toISOString() : undefined,
        },
      });
    } else {
      mounted.current = true;
    }
    // eslint-disable-next-line
  }, [
    debouncedName,
    debouncedAlbumCode,
    debouncedDescription,
    debouncedReleaseDate,
    debouncedSubtitle,
  ]);

  const onChangeSubtitle: ChangeEventHandler<HTMLInputElement> = (e) => {
    const v = e.target.value;
    setSubtitle(v.length < 100 ? v : v.slice(0, 100));
  };

  return (
    <div className='flex w-full justify-between gap-x-6 py-3 px-4 xl:gap-x-24 xl:px-16'>
      <div className='h-40 w-40 flex-shrink-0'>
        <ArtworkUploader />
      </div>
      <div className='grid flex-grow grid-cols-2 gap-x-6 xl:gap-x-24'>
        <div className='space-y-3'>
          <div className='grid grid-cols-2 gap-x-4'>
            <div>
              <label className='mb-1 block font-medium text-gray-700 dark:text-gray-200'>
                Album name
              </label>
              <input
                onChange={(e) => setName(e.target.value)}
                value={name}
                type='text'
                className={`block w-full flex-1 shadow-sm sm:text-sm ${globalThemeStyles.inputbox}`}
                placeholder='Title of your project'
              />
            </div>
            <div>
              <label className='mb-1 block font-medium text-gray-700 dark:text-gray-200'>
                Album subtitle
              </label>
              <input
                onChange={onChangeSubtitle}
                value={subtitle}
                type='text'
                className={`block w-full flex-1 shadow-sm sm:text-sm ${globalThemeStyles.inputbox}`}
                placeholder='Subtitle of your project'
                data-testid='album-subtitle-input'
              />
            </div>
          </div>
          <div className='grid grid-cols-[2fr,1fr] gap-x-4'>
            <div>
              <label className='block font-medium text-gray-700 dark:text-gray-200'>
                Code
              </label>
              <div className='mt-1 flex'>
                <input
                  onChange={(e) => setAlbumCode(e.target.value)}
                  value={albumCode}
                  type='text'
                  className={`block w-full flex-1 shadow-sm sm:text-sm ${globalThemeStyles.inputbox}`}
                  placeholder='Code of your Album'
                  data-testid='album-code-input'
                />
              </div>
            </div>
            <div>
              <label className='block font-medium text-gray-700 dark:text-gray-200'>
                Release date
              </label>
              <div className='mt-1 flex'>
                <ReactDatePicker
                  selected={releaseDate}
                  onChange={(date) => setReleaseDate(date)}
                  className={`border-1 block w-full flex-1 shadow-sm sm:text-sm ${globalThemeStyles.inputbox}`}
                  dateFormat='dd/MM/yyyy'
                  id='releaseDate'
                />
              </div>
            </div>
          </div>
        </div>
        <div className='-mt-2 flex flex-col'>
          <div className='mb-1 flex items-end justify-between font-medium text-gray-700 dark:text-gray-200'>
            Description
            <button
              className='flex items-center space-x-2 rounded bg-emerald-600 px-4 py-1 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
              onClick={() => {
                setIsOpen(true);
              }}
            >
              <div>Generate</div>
            </button>
          </div>
          <InlineTextAreaEdit
            key='input8'
            isDisabled={false}
            rows={3}
            className={`block w-full flex-grow sm:text-sm ${globalThemeStyles.inputbox} MiniScrollbar max-h-[116px] resize-none`}
            value={description}
            setValue={setDescription}
            testid='album-description-input'
          />
        </div>
      </div>
      <DescriptionsDialog
        tags={activeActiveAlbumTags.map((v) => v.name).join(',')}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setDescription={setDescription}
        albumName={activeAlbum?.name || ''}
      />
    </div>
  );
}
