import axios from 'axios';
import { useState } from 'react';
import { FaFolder, FaFolderPlus, FaSpinner } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import api from '../../api';
import { IProject } from '../../api/services/project.service';
import { useAuth } from '../../hooks/useAuth';
import TextEditDialog from '../shared/TextEditDialog';
import AlbumCard from './AlbumCard';
import { useSearch } from '../../hooks/useSearch';
import { SearchInput } from '../shared/SearchInput';
import { Card } from '../shared/Card';

interface Props {
  className?: string;
  albums: IProject[] | undefined;
  loading: boolean;
}

export default function AlbumSection({ className, albums, loading }: Props) {
  let navigate = useNavigate();
  const { activeTeam } = useAuth();
  const [isAlbumAddDialogOpen, setIsAlbumAddDialogOpen] = useState(false);

  const {
    onSearchChange,
    found: foundAlbums,
    searchString,
  } = useSearch<IProject>({
    data: albums || [],
    filterFn: (v: string) => (album) => album.name.toLowerCase().includes(v),
  });

  async function addAlbum({ name }: { name: string }) {
    if (!activeTeam) {
      toast.error('No team selected!');
      return;
    }
    try {
      const { data } = await api.album.addAlbum({
        type: 'ALBUM',
        name,
        teamId: activeTeam?.id,
      });
      if (data.result) {
        navigate(`/album/${data.result.id}`);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <Card
      testid='album-section'
      headerChildren={
        <SearchInput onChange={onSearchChange} value={searchString} />
      }
      title='Albums'
      className={className}
      onClickAdd={() => {
        setIsAlbumAddDialogOpen(true);
      }}
      icon={<FaFolder size={20} className='text-indigo-700' />}
    >
      <TextEditDialog
        testid='album-add-dialog'
        isOpen={isAlbumAddDialogOpen}
        title={'New Album'}
        initialValue={'New Album'}
        onCancel={() => {
          setIsAlbumAddDialogOpen(false);
        }}
        onSubmit={(newValue: string) => {
          addAlbum({ name: newValue });
          setIsAlbumAddDialogOpen(false);
        }}
      />
      {loading && (
        <div className='flex items-center justify-center p-20'>
          <FaSpinner size={30} className='animate-spin' />
        </div>
      )}
      {!loading && albums && albums.length > 0 && (
        <div className='MiniScrollbar grid grid-cols-1 gap-8 overflow-auto p-4 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-3'>
          {foundAlbums.map((album) => (
            <AlbumCard key={album.id} album={album} />
          ))}
        </div>
      )}
      {!loading && albums && albums.length === 0 && (
        <div className='flex flex-col items-center justify-center p-8'>
          <div className='mb-2 pt-52 font-semibold'>No Albums yet</div>
          <div className='inline-flex items-center space-x-2'>
            <div>Create one via the</div>
            <FaFolderPlus
              size={24}
              className='cursor-pointer text-indigo-700'
              onClick={() => setIsAlbumAddDialogOpen(true)}
            />
            <div>button on the top right</div>
          </div>
        </div>
      )}
    </Card>
  );
}
