import { Dialog, Transition } from '@headlessui/react';
import { Fragment, useEffect, useRef, useState } from 'react';
import { classNames } from '../../utils';
import { globalThemeStyles } from '../../config/globalStyles';

export default function TextEditDialog({
  title,
  placeholder = '',
  initialValue = '',
  isOpen,
  onCancel,
  onSubmit,
  testid = '',
}: {
  placeholder?: string;
  initialValue?: string;
  title: string;
  isOpen: boolean;
  onCancel: () => void;
  onSubmit: (newName: string) => void;
  testid?: string;
}) {
  const [name, setName] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (initialValue && isOpen) {
      setName(initialValue);
    }
  }, [isOpen]);

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      onSubmit(name);
    } else if (event.key === 'Escape') {
      onCancel();
    }
  };
  return (
    <>
      <Transition
        appear
        show={isOpen}
        as={Fragment}
        afterEnter={() => inputRef.current?.focus()}
      >
        <Dialog as='div' className='relative z-10' onClose={() => onCancel()}>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div className='fixed inset-0 bg-black bg-opacity-25' />
          </Transition.Child>

          <div className='fixed inset-0 overflow-y-auto'>
            <div className='flex min-h-full items-center justify-center p-4 text-center'>
              <Transition.Child
                as={Fragment}
                enter='ease-out duration-300'
                enterFrom='opacity-0 scale-95'
                enterTo='opacity-100 scale-100'
                leave='ease-in duration-200'
                leaveFrom='opacity-100 scale-100'
                leaveTo='opacity-0 scale-95'
              >
                <Dialog.Panel
                  className={classNames(
                    'w-full max-w-xl transform rounded-lg py-4 px-4 text-left align-middle shadow-xl transition-all lg:py-6 lg:px-6',
                    globalThemeStyles.box
                  )}
                >
                  <div className='flex items-center justify-between'>
                    <div className='flex items-center text-xl font-semibold leading-6'>
                      <span>{title}</span>
                    </div>
                  </div>
                  <div className='mt-5 space-y-6'>
                    <input
                      data-testid={testid ? `${testid}-input` : undefined}
                      autoFocus
                      ref={inputRef}
                      onKeyDown={onKeyDown}
                      id='name'
                      name='name'
                      type='text'
                      autoComplete='name'
                      required
                      className={classNames(
                        'w-full rounded-md border border-gray-300 px-3 py-2 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 disabled:bg-slate-200 sm:text-sm',
                        globalThemeStyles.inputbox
                      )}
                      placeholder={placeholder}
                      onChange={(targetEvent) =>
                        setName(targetEvent.target.value)
                      }
                      value={name}
                    />
                  </div>
                  <div className='mt-4 flex justify-end space-x-4'>
                    <button
                      className={classNames(
                        'rounded p-2 px-4 font-semibold',
                        globalThemeStyles.hoverItem
                      )}
                      onClick={onCancel}
                    >
                      Cancel
                    </button>
                    <button
                      className='rounded bg-indigo-600 p-2 px-4 font-semibold text-white hover:bg-indigo-800'
                      onClick={() => onSubmit(name)}
                      data-testid={testid ? `${testid}-submit-btn` : undefined}
                    >
                      OK
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
}
