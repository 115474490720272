import SubmissionSection from '../components/dashboard/SubmissionSection';
import { useAuth } from '../hooks/useAuth';

import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import api from '../api';
import { IProject } from '../api/services/project.service';
import NoTeamSection from '../components/dashboard/NoTeamSection';
import { useTeamLogo } from '../hooks/useTeamLogo';

export default function Submissions() {
  const { user, activeTeam } = useAuth();
  const { tenantLandingImageUrl } = useTeamLogo();

  const [projects, setProjects] = useState<IProject[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (activeTeam !== null) {
      fetchProjects();
    }
  }, [activeTeam]);

  async function fetchProjects() {
    try {
      setLoading(true);
      const { data } = await api.project.getProjects();
      if (data.result) {
        setProjects(data.result);
        setLoading(false);
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  const statusOrder = ['DRAFT', 'REQUESTED', 'SUBMITTED', 'DONE'];

  return (
    <>
      {user && (
        <div className='flex grow flex-col items-center'>
          {/* Projects */}
          {activeTeam && (
            <>
              {tenantLandingImageUrl && (
                <div className=''>
                  <img
                    className='mt-4 max-h-16'
                    src={tenantLandingImageUrl}
                    alt='tenantLandingImageUrl'
                  />
                </div>
              )}
              <div className='mt-20 flex w-full px-5 md:mt-8 md:px-20 lg:px-36'>
                <SubmissionSection
                  className='h-128'
                  projects={projects?.sort((a, b) => {
                    // First, compare by status
                    const statusComparison =
                      statusOrder.indexOf(a.status) -
                      statusOrder.indexOf(b.status);
                    if (statusComparison !== 0) {
                      return statusComparison;
                    }
                    // If statuses are the same, compare by createdAt
                    return a.createdAt > b.createdAt ? -1 : 1;
                  })}
                  loading={loading}
                />
              </div>
            </>
          )}
          {!activeTeam && (
            <div className='mt-20 flex w-full px-5 md:mt-8 md:px-20 lg:px-36'>
              <NoTeamSection />
            </div>
          )}
        </div>
      )}
    </>
  );
}
