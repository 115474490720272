import AlbumFileList from '../components/studio/sections/AlbumFileList';
import AlbumInfo from '../components/studio/sections/AlbumInfo';
import AlbumTrackInfo from '../components/studio/sections/AlbumTrackInfo';
import {
  AlbumStudioStateProvider,
  useAlbumStudioState,
} from '../components/studio/albumState';
import { useAuth } from '../hooks/useAuth';
import NotFound from './generic/NotFound';
import { AlbumActionBar } from '../components/studio/AlbumActionBar';
// import Forbidden from './generic/Forbidden';

function AlbumStudioViewInternal() {
  const { isAuthenticated } = useAuth();
  const { activeAlbum, activeTrack, isLoading } = useAlbumStudioState();

  if (isLoading) return null;

  return (
    <>
      {/* TODO: handle not found and forbidden 
       {activeAlbumId !== null &&
        activeAlbum === null &&
        (activeAlbumMembers.length === 0 ? <Forbidden /> : <NotFound />)} */}
      {!activeAlbum === null && <NotFound />}
      {isAuthenticated && activeAlbum && (
        <div className='flex flex-1 grow flex-col'>
          <div className='flex flex-grow flex-col space-y-4 md:flex-1'>
            <AlbumActionBar />
            <div className='flex flex-grow flex-col space-y-4 md:flex-1'>
              <AlbumInfo />
              <div className='flex flex-1 flex-grow gap-4'>
                <div className='flex basis-1/3 flex-col space-y-6'>
                  <div className='flex flex-1 items-stretch'>
                    <AlbumFileList />
                  </div>
                </div>
                {activeTrack && <AlbumTrackInfo />}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default function AlbumStudioView() {
  return (
    <AlbumStudioStateProvider>
      <AlbumStudioViewInternal />
    </AlbumStudioStateProvider>
  );
}
