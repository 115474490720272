import copy from 'copy-to-clipboard';
import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
// @ts-ignore
import SockJsClient from 'react-stomp';
import { BACKEND_BASE_URL } from './config/config';
import { useAuth } from './hooks/useAuth';
import { useLocalStorage } from './hooks/useLocalStorage';
import { Theme } from './types';
import { FEATURES } from './config/features';

interface InternalStateProps {
  theme: Theme;
  setTheme: (value: Theme | ((val: Theme) => Theme)) => void;
}

let GlobalStateContext = React.createContext<InternalStateProps>(null!);

export function GlobalStateProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const [theme, setTheme] = useLocalStorage<Theme>('theme', 'light');

  const [headers, setHeaders] = useState({});
  const [options, setOptions] = useState({});

  const { user, accessToken, isFeatureActive } = useAuth();

  useEffect(() => {
    setHeaders({
      Authorization: `Bearer ${accessToken}`,
    });
    setOptions({
      transports: ['websocket', 'hybi-10', 'hixie-76', 'rfc6455'],
    });
  }, [accessToken]);

  const [searchParams] = useSearchParams();
  const showAccess = searchParams.get('showAccess');

  // TODO: remove in prod
  useEffect(() => {
    if (isFeatureActive(FEATURES.KTR_ADMIN_ACCESS_COPY)) {
      if (showAccess !== null && accessToken !== null) {
        copy(accessToken);
      }
    }
  }, []);

  return (
    <GlobalStateContext.Provider
      value={{
        theme,
        setTheme,
      }}
    >
      {user && isFeatureActive(FEATURES.KTR_WSS) && (
        <SockJsClient
          url={`${BACKEND_BASE_URL}/ws`}
          topics={['/user/notifications/' + user.id]}
          onMessage={(msg: any) => {
            console.log('RECEIVED MESSAGE', msg);
          }}
          onConnect={() => console.log('connected')}
          onDisconnect={() => console.log('disconnected')}
          onConnectFailure={(e: Error) => console.log('connectFailure', e)}
          headers={headers}
          options={options}
          getRetryInterval={(count: number) => {
            return 1000 * count;
          }}
        />
      )}
      {children}
    </GlobalStateContext.Provider>
  );
}

export function useGlobalState() {
  return React.useContext(GlobalStateContext);
}
