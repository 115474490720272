import { GrTextAlignLeft } from 'react-icons/gr';
import { FaTimes } from 'react-icons/fa';
import GenericDialog from '../../shared/GenericDialog';
import { useEffect, useState } from 'react';
import api from '../../../api';
import Loader from '../../shared/Loader';
import { DescriptionResponse } from '../../../api/services/ai.service';

interface Props {
  setIsOpen: (v: boolean) => void;
  isOpen: boolean;
  setDescription: (v: string) => void;
  tags: string;
  albumName: string;
}

export const DescriptionsDialog = ({
  setIsOpen,
  isOpen,
  setDescription,
  tags,
  albumName,
}: Props) => {
  const [isLoadingDescriptions, setIsLoadingDescriptions] = useState(false);
  const [descriptions, setDescriptions] = useState<string[]>([]);

  useEffect(() => {
    if (isOpen) {
      setIsLoadingDescriptions(true);
      Promise.allSettled(
        Array(3)
          .fill(1)
          .map((_) =>
            api.ai.getDescription({
              title: albumName,
              tags: tags,
              jobID: String(Date.now()),
              jobStarted: new Date().toISOString(),
            })
          )
      ).then((res) => {
        const result = res
          .filter((r) => r.status === 'fulfilled')
          .map((r) =>
            JSON.parse(
              (r as PromiseFulfilledResult<DescriptionResponse>).value.result
                .body
            )
          );

        setDescriptions(result);
        setIsLoadingDescriptions(false);
      });
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return (
    <GenericDialog
      onUpperRightClick={() => setIsOpen(false)}
      upperRightIcon={<FaTimes size={20} />}
      header='Album description'
      headerIcon={<GrTextAlignLeft size={20} />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      footer={<></>}
    >
      <div className='flex min-h-[400px] flex-col'>
        {isLoadingDescriptions ? (
          <div className='flex flex-grow flex-col items-center justify-center'>
            <div className='-mt-6 flex flex-col gap-y-2 text-lg text-slate-700 dark:text-slate-200'>
              <Loader className='!static mx-auto !h-6 !w-6 !bg-transparent' />
              Generating...{' '}
            </div>
          </div>
        ) : (
          <div>
            {descriptions.map((d) => (
              <button
                className='border-1 block w-full flex-1 rounded-md border-gray-300 bg-gray-50 p-3 text-start text-slate-700 shadow-sm transition-colors hover:bg-gray-200 disabled:bg-slate-200 dark:border-gray-600 dark:bg-slate-800 dark:text-slate-200 dark:disabled:bg-slate-600 sm:text-sm'
                key={d}
                onClick={(e) => {
                  setDescription(d);
                  setIsOpen(false);
                }}
              >
                {d}
              </button>
            ))}
          </div>
        )}
      </div>
    </GenericDialog>
  );
};
