import { FaBug, FaExpandArrowsAlt, FaTerminal, FaTimes } from 'react-icons/fa';
import GenericDialog from '../shared/GenericDialog';
import { useAuth } from '../../hooks/useAuth';
import { classNames } from '../../utils';
import { useEffect, useState } from 'react';
import {
  UrlCopyAndActionElement,
  UrlCopyElement,
} from '../shared/UrlCopyElement';
import SwitchRow from '../shared/SwitchRow';
import { BACKEND_BASE_URL } from '../../config/config';
import Input from '../shared/Input';
import { MdSwitchAccessShortcutAdd } from 'react-icons/md';
interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export function AdminDialog({ isOpen, setIsOpen }: Props) {
  const { user, setUser, activeFeatures, activeTeam, accessToken } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [backend, setBackend] = useState<string>();

  const close = () => {
    setIsOpen(false);
  };

  function toggelFeature(id: string, active: boolean) {}

  function updateBackend(backend: string | undefined) {
    if (!backend) {
      return;
    }
    const url = new URL(window.location.href);
    setBackend(backend);
    url.searchParams.set('backend', backend);
    window.history.pushState({}, '', url);
    window.location.reload();
  }

  useEffect(() => {
    const backend = localStorage.getItem('backend') || BACKEND_BASE_URL;
    console.log('backend', backend);
    setBackend(backend);
  }, []);

  return (
    <>
      <GenericDialog
        onUpperRightClick={close}
        upperRightIcon={
          <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
        }
        header={'Admin'}
        headerIcon={<FaTerminal size={20} />}
        isOpen={isOpen}
        setIsOpen={(v: boolean) => {
          setIsOpen(v);
        }}
        footer={
          <button
            className=' rounded p-1 px-4 font-semibold text-slate-600 transition-colors hover:bg-slate-200 disabled:opacity-50 dark:text-slate-300 dark:hover:bg-slate-700'
            onClick={close}
            disabled={isLoading}
          >
            Close
          </button>
        }
      >
        <div>
          <label className='font-semibold'>
            User ID ({user?.email || 'NO USER ID'})
          </label>
          <UrlCopyAndActionElement
            url={user?.id || 'No User ID'}
            action={() =>
              window.open(
                `https://admin.dev.ktr.studio/user/${user?.id}`,
                '_blank',
                'noreferrer'
              )
            }
            actionIcon={<FaExpandArrowsAlt size={15} />}
          />
        </div>
        <div>
          <label className='font-semibold'>
            Team ID ({activeTeam?.name || 'No Team name'})
          </label>
          <UrlCopyAndActionElement
            url={activeTeam?.id || 'No Team Id'}
            action={() =>
              window.open(
                `https://admin.dev.ktr.studio/team/${activeTeam?.id}`,
                '_blank',
                'noreferrer'
              )
            }
            actionIcon={<FaExpandArrowsAlt size={15} />}
          />
        </div>
        <div>
          <label className='font-semibold'>Access token</label>
          <UrlCopyElement url={accessToken || 'No Access Token'} />
        </div>
        <div className='flex flex-row items-center justify-between space-x-2'>
          <div className='flex w-full items-end space-x-2'>
            <div className='w-full'>
              <Input
                label='Backend'
                value={backend}
                type='text'
                name='backend'
                id='backend'
                className={` `}
                onChange={(e) => setBackend(e.target.value)}
              />
            </div>
            <button
              className={classNames(
                'h-10 items-center space-x-2 rounded bg-teal-600 px-4 py-2 font-semibold text-white hover:bg-teal-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
              )}
              onClick={() => updateBackend(backend)}
            >
              <MdSwitchAccessShortcutAdd />
            </button>
          </div>
        </div>
        <div className='flex flex-row space-x-2'>
          <button
            className={classNames(
              'flex items-center space-x-2 self-stretch rounded bg-emerald-600 px-4 py-2 font-semibold text-white hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
            )}
            onClick={() => updateBackend('https://core.dev.ktr.studio/api')}
          >
            DEV
          </button>
          <button
            className={classNames(
              'flex items-center space-x-2 self-stretch rounded bg-yellow-600 px-4 py-2 font-semibold text-white hover:bg-yellow-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
            )}
            onClick={() => updateBackend('http://localhost:8080/api')}
          >
            LOCAL
          </button>
        </div>
        <div>
          <p className='font-semibold'>Features</p>
        </div>
        {activeFeatures.map((feature) => {
          return (
            <SwitchRow
              disabled={false}
              title={feature.name || 'Feature'}
              icon={<FaBug size={20} className='mr-2 text-indigo-700' />}
              isActive={true}
              onChange={() => {
                toggelFeature(feature.id, false);
              }}
            >
              <span className='text-sm text-slate-500'>
                {feature.description ||
                  'This feature does not have a description'}
              </span>
            </SwitchRow>
          );
        })}
      </GenericDialog>
    </>
  );
}
