// Components/IconItem.tsx
import React from 'react';
import { globalThemeStyles } from '../../config/globalStyles';

export interface Item {
  title: string;
  description: string;
  icon: React.ElementType;
  background: string;
  onClick?: () => void;
}

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ');
}

function ActionItem({ item }: { item: Item }) {
  return (
    <div
      key={item.title}
      className='relative -m-2 flex cursor-pointer items-center space-x-4 rounded-xl p-2 focus-within:ring-2 focus-within:ring-indigo-500 hover:bg-slate-50 hover:dark:bg-slate-800'
      onClick={item.onClick}
    >
      <div
        className={classNames(
          item.background,
          'flex h-16 w-16 flex-shrink-0 items-center justify-center rounded-lg'
        )}
      >
        <item.icon className='h-6 w-6 text-white' aria-hidden='true' />
      </div>
      <div className='hidden lg:block'>
        <h3 className='text-sm font-medium text-slate-900 dark:text-slate-200'>
          <p className='focus:outline-none'>
            <span className='absolute inset-0' aria-hidden='true' />
            <span>{item.title}</span>
            <span aria-hidden='true'> &rarr;</span>
          </p>
        </h3>
        <p className='mt-1 text-sm text-slate-500 dark:text-slate-400'>
          {item.description}
        </p>
      </div>
    </div>
  );
}

export { ActionItem };
