import React, { PropsWithChildren, useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import type { Identifier } from 'dnd-core';
import { classNames } from '../../../utils';

const ItemTypes = {
  Item: 'item',
};

export interface IDraggableItem<T> {
  index: number;
  item: T;
}

interface Props<T> {
  move: (dragIndex: number, hoverIndex: number, hoverItem: T) => void;
  item: T;
}

export const DraggableItem = <T,>({
  children,
  move,
  index,
  item,
}: PropsWithChildren<Props<T> & IDraggableItem<T>>) => {
  const ref = useRef<HTMLDivElement>(null);
  const [{ handlerId }, drop] = useDrop<
    IDraggableItem<T>,
    void,
    { handlerId: Identifier | null }
  >({
    accept: ItemTypes.Item,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    drop(dragItem: IDraggableItem<T>) {
      if (!ref.current) {
        return;
      }
      const dragIndex = dragItem.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      move(dragIndex, hoverIndex, dragItem.item);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: ItemTypes.Item,
    item: () => {
      return { index, item };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  drag(drop(ref));

  return (
    <div
      ref={ref}
      className={classNames(isDragging ? 'opacity-50' : '')}
      data-handler-id={handlerId}
    >
      {children}
    </div>
  );
};
