import { FaGlobe, FaNewspaper, FaTimes } from 'react-icons/fa';
import { globalThemeStyles } from '../../config/globalStyles';
import { BiNoEntry } from 'react-icons/bi';
import { useState } from 'react';
import GenericDialog from '../shared/GenericDialog';
import axios from 'axios';
import { BACKEND_BASE_URL } from '../../config/config';
import { toast } from 'react-toastify';

function RegisterForm({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
}) {
  const [email, setEmail] = useState('');

  async function sendEmail() {
    try {
      await axios.post(`${BACKEND_BASE_URL}/integration/contact`, {
        firstname: 'new',
        lastname: 'opp',
        email: email,
        message: 'I am interested in creating a label. Please keep me updated.',
      });
      toast.success("We'll keep you updated!");
      setIsOpen(false); // Close the dialog after sending the email
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  return (
    <GenericDialog
      onUpperRightClick={() => setIsOpen(false)}
      upperRightIcon={
        <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
      }
      header="We'll keep you updated"
      headerIcon={<FaNewspaper size={20} />}
      isOpen={isOpen}
      setIsOpen={setIsOpen}
      footer={<></>}
    >
      <div className='mt-2 max-w-xl text-base text-gray-600 dark:text-gray-300'>
        <p className='pb-4'>
          Creation of your own label is currently not available to the public.
        </p>
        <p>
          Sign up here to be among the first to access it once it becomes
          available to everyone.
        </p>
      </div>
      <div className='mt-5 sm:flex sm:items-center'>
        <div className='w-full sm:max-w-sm'>
          <label htmlFor='email' className='sr-only'>
            Email
          </label>
          <input
            type='email'
            name='email'
            id='email'
            className={`w-full ${globalThemeStyles.inputbox}`}
            placeholder='you@example.com'
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>
        <button
          type='submit'
          className='mt-3 inline-flex w-full items-center justify-center rounded-md bg-indigo-600 px-4 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:ml-3 sm:mt-0 sm:w-auto'
          onClick={sendEmail}
        >
          Sign me up!
        </button>
      </div>
    </GenericDialog>
  );
}

export default function NoTeamSection() {
  const [showForm, setShowForm] = useState(false);

  const handleClick = () => {
    setShowForm(!showForm);
  };

  return (
    <>
      {showForm && <RegisterForm isOpen={showForm} setIsOpen={setShowForm} />}
      <div className='flex flex-col items-center text-center'>
        <BiNoEntry size={64} className='text-indigo-500' />
        <h3 className='mt-2 text-2xl font-semibold text-gray-900'>
          No Label Assigned
        </h3>
        <p className='mt-8 text-lg text-gray-600 dark:text-gray-400'>
          It appears you are not currently registered as an active artist or
          staff member of a label.
        </p>
        <p className='mt-1 text-lg text-gray-600 dark:text-gray-400'>
          For access, please contact your label representative.
        </p>

        <div className='mt-24'>
          <p className='mb-6 text-lg text-gray-600 dark:text-gray-400'>
            Are you part of a music label and looking to streamline your library
            management?
          </p>
          <button
            type='button'
            onClick={handleClick}
            className='inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-lg font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
          >
            <FaGlobe className='-ml-0.5 mr-1.5 h-5 w-5' aria-hidden='true' />
            Explore our platform
          </button>
        </div>
      </div>
    </>
  );
}
