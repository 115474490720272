import axios from 'axios';
import api from '../../../../api';
import { IFile } from '../../../../api/services/file.service';
import { IProject } from '../../../../api/services/project.service';
import { IStateProp } from '../../studioState';
import { SpecialFieldType } from './FileUploadField';
import { toast } from 'react-toastify';

export function checkContentTypeMatch(
  contentType: string,
  targetContentTypes: {
    [key: string]: string[];
  }
) {
  for (const pattern in targetContentTypes) {
    if (targetContentTypes.hasOwnProperty(pattern)) {
      if (matchPattern(contentType, pattern)) {
        return true;
      }
    }
  }
  return false;
}

function matchPattern(inputString: string, pattern: string) {
  const patternRegex = new RegExp(
    '^' + pattern.replace('*', '.*').replace('/', '\\/') + '$'
  );

  return patternRegex.test(inputString);
}

export async function uploadFile(
  file: File,
  activeFile: IFile | undefined,
  refreshData: (state: IStateProp) => void,
  trackId: string,
  activeProject: IProject,
  fileFieldRole: string,
  setFileUploadProgress: (percent: number) => void,
  onUploadCompleted: (file: IFile) => void
) {
  if (activeFile) {
    await deleteFile(activeFile, refreshData);
  }

  console.log(`Uploading file ${file.name} to track ${trackId}`);
  const fileObj = await addFile(file, trackId, activeProject, fileFieldRole);
  // // get upload url
  // onUploadStarted(fileObj, trackId);
  const result = await api.file.getFilePresignedUploadUrl(fileObj.id);
  const url = result.data.result?.presignedUrl;
  try {
    // blocks until file uploaded successfully
    await upload(file, url, setFileUploadProgress);
    // set file status to READY
  } catch (error) {
    await api.file.deleteFile(fileObj.id);
    toast.error('Failed to upload file');
    return;
  }

  await updateFile(fileObj);
  onUploadCompleted(fileObj);
}

async function deleteFile(
  file: IFile,
  refreshData: (state: IStateProp) => void
) {
  const { data: fileResponse } = await api.file.deleteFile(file.id);
  refreshData('activeFiles');
  return fileResponse.result;
}

async function addFile(
  file: File,
  trackId: string,
  activeProject: IProject,
  fileFieldRole: string
) {
  const { data: fileResponse } = await api.file.addFile({
    name: file.name,
    size: file.size,
    parentId: trackId,
    contentType: file.type,
    projectId: activeProject.id,
    tenantId: activeProject.teamId,
    // role: fileField.targetRole,
    role: fileFieldRole,
  });
  return fileResponse.result;
}

async function upload(
  file: File,
  url: string,
  setFileUploadProgress: (percent: number) => void
) {
  return await axios.put(url, file, {
    headers: {
      'Content-Type': file.type,
    },
    onUploadProgress: (progress: any) => {
      const percentUploadedRaw = (progress.loaded / progress.total) * 100;
      const percentUploaded = Math.round(percentUploadedRaw);
      setFileUploadProgress(percentUploaded);
    },
  });
}

async function updateFile(file: IFile) {
  const { data: fileResponse } = await api.file.updateFilePartially(file.id, {
    status: 'READY',
  });
  return fileResponse.result;
}

export interface FileInUpload {
  id: string;
  field: SpecialFieldType | undefined;
  file: File;
  activeProject: IProject;
  progress: number;
  trackId: string;
}
