import * as React from 'react';
import { IFile } from '../../../../api/services/file.service';
import { IStateProp } from '../../studioState';
import { FileInUpload } from './FileTabWindow.helpers';
import { toast } from 'react-toastify';

interface FilesTabContextType {
  filesInUpload: FileInUpload[];
  addFileToUpload: (input: FileInUpload) => void;
  setFilesInUpload: React.Dispatch<React.SetStateAction<FileInUpload[]>>;
  onUploadCompleted: (
    refreshData: (state: IStateProp) => void,
    id: string
  ) => (file: IFile) => void;
}

export const FilesTabContext = React.createContext<FilesTabContextType>(
  {} as FilesTabContextType
);

export const FilesTabProvider = ({ children }: React.PropsWithChildren) => {
  const [filesInUpload, setFilesInUpload] = React.useState<FileInUpload[]>([]);

  const addFileToUpload = (input: FileInUpload) => {
    setFilesInUpload((prev) => [...prev, input]);
  };

  const deleteFileFromToUpload = (id: string) => {
    setFilesInUpload((prev) => prev.filter((f) => f.id !== id));
  };

  const onUploadCompleted =
    (refreshData: (state: IStateProp) => void, id: string) => (file: IFile) => {
      refreshData('activeFiles');
      refreshData('activeProjectTracks');
      toast.success(`File ${file.name} uploaded successfully`);
      deleteFileFromToUpload(id);
    };

  return (
    <FilesTabContext.Provider
      value={{
        setFilesInUpload,
        addFileToUpload,
        filesInUpload,
        onUploadCompleted,
      }}
    >
      {children}
    </FilesTabContext.Provider>
  );
};
