import { Menu, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import {
  FaCog,
  FaRegMoon,
  FaRegSun,
  FaSignOutAlt,
  FaSpinner,
  FaTerminal,
  FaUser,
  FaUserCircle,
  FaUserNinja,
} from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import stringToColor from 'string-to-color';
import { globalThemeStyles } from '../../../config/globalStyles';
import { useAuth } from '../../../hooks/useAuth';
import { useGlobalState } from '../../../state';
import { classNames } from '../../../utils';
import { FEATURES } from '../../../config/features';

interface Props {
  onClickViewProfile: () => void;
  onClickViewAdmin: () => void;
}

export function ProfilePicDropdown({
  onClickViewProfile,
  onClickViewAdmin,
}: Props) {
  const { theme, setTheme } = useGlobalState();
  let navigate = useNavigate();
  const { logout, user, isFeatureActive, activeTeam } = useAuth();

  // const [settingsOpen, setSettingsOpen] = useState(false);
  // const [subMgmtOpen, setSubMgmtOpen] = useState(false);
  // const [searchBarOpen, setSearchBarOpen] = useState(false);

  async function triggerLogout() {
    logout();
    navigate('/login');
    toast.info('Logged out successfully');
  }

  const handleTheme = () => {
    setTheme && setTheme(theme === 'light' ? 'dark' : 'light');
  };
  return (
    <Menu as='div' className='relative z-10 ml-4 flex-shrink-0'>
      <div>
        <Menu.Button
          className='flex rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
          data-testid='profile-dropdown-btn'
        >
          <span className='sr-only'>Open user menu</span>
          {user && (
            <FaUserCircle
              className='h-8 w-8 rounded-full bg-white'
              color={stringToColor(user?.id)}
            />
          )}
          {!user && (
            <FaSpinner className='h-8 w-8 animate-spin rounded-full bg-white' />
          )}
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter='transition ease-out duration-100'
        enterFrom='transform opacity-0 scale-95'
        enterTo='transform opacity-100 scale-100'
        leave='transition ease-in duration-75'
        leaveFrom='transform opacity-100 scale-100'
        leaveTo='transform opacity-0 scale-95'
      >
        <Menu.Items
          className={classNames(
            'absolute right-0 mt-2 w-48 origin-top-right divide-y rounded-md py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
            globalThemeStyles.box
          )}
        >
          <div className='px-1 py-1 '>
            <Menu.Item>
              <div
                onClick={handleTheme}
                className={classNames(
                  globalThemeStyles.hoverItem,
                  'flex items-center px-4 py-2 text-sm'
                )}
              >
                {theme === 'light' ? (
                  <FaRegMoon className='mr-2' />
                ) : (
                  <FaRegSun className='mr-2' />
                )}
                {theme === 'light' ? 'Dark mode' : 'Light mode'}
              </div>
            </Menu.Item>
            <Menu.Item>
              <div
                onClick={onClickViewProfile}
                className={classNames(
                  globalThemeStyles.hoverItem,
                  'flex items-center px-4 py-2 text-sm'
                )}
              >
                <FaUser className='mr-2' />
                User Profile
              </div>
            </Menu.Item>
            {activeTeam &&
              (activeTeam.teamMembershipDTO?.role === 'OWNER' ||
                activeTeam.teamMembershipDTO?.role === 'MEMBER') && (
                <Menu.Item>
                  <div
                    onClick={() => navigate('/team')}
                    className={classNames(
                      globalThemeStyles.hoverItem,
                      'flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    <FaCog className='mr-2' />
                    Label Settings
                  </div>
                </Menu.Item>
              )}
          </div>
          {user?.role === 'ADMIN' && (
            <div className='px-1 py-1 '>
              {user?.role === 'ADMIN' && (
                <Menu.Item>
                  <div
                    onClick={onClickViewAdmin}
                    className={classNames(
                      globalThemeStyles.hoverItem,
                      'flex items-center px-4 py-2 text-sm'
                    )}
                  >
                    <FaTerminal className='mr-2' />
                    Admin info
                  </div>
                </Menu.Item>
              )}
              {user?.role === 'ADMIN' &&
                isFeatureActive(FEATURES.KTR_IMPERSONATION) && (
                  <Menu.Item>
                    <div
                      onClick={() => navigate('/impersonate')}
                      className={classNames(
                        globalThemeStyles.hoverItem,
                        'flex items-center px-4 py-2 text-sm'
                      )}
                    >
                      <FaUserNinja className='mr-2' />
                      Impersonate User
                    </div>
                  </Menu.Item>
                )}
            </div>
          )}

          <Menu.Item>
            <div
              onClick={() => triggerLogout()}
              className={classNames(
                globalThemeStyles.hoverItem,
                'flex items-center px-4 py-2 text-sm'
              )}
            >
              <FaSignOutAlt className='mr-2' />
              Log out
            </div>
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
