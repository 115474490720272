import { IResponse } from '..';
import client from '../client';

export interface INotification {
  id: string;
  eventName: string;
  title: string;
  text: string;
  actorId: string;
  createdAt: string;
  metaData: any;
}

// export interface INotificationLink {
//   userId: string;
//   notificationId: string;
//   status: string;
//   notification: INotification;
// }

export interface INotificationSubscription {
  userId: string;
  targetEntityId: string;
  events: string[];
}

export interface INotificationSettings {
  userId: string;
  emailEnabled: boolean;
}

async function getNotifications() {
  return client.get<IResponse<INotification[]>>(`/notification`);
}

async function updateNotificationSubscription(
  subscription: INotificationSubscription
) {
  return client.put<IResponse<INotificationSubscription>>(
    `/notification/subscription`,
    subscription
  );
}

async function getNotificationSubscriptions() {
  return client.get<IResponse<INotificationSubscription[]>>(
    `/notification/subscription`
  );
}

async function updateNotificationSettings(
  settings: Partial<INotificationSettings>
) {
  return client.patch<IResponse<INotificationSettings>>(
    `/notification/settings`,
    settings
  );
}

async function getNotificationSettings() {
  return client.get<IResponse<INotificationSettings>>(`/notification/settings`);
}

const api = {
  getNotificationSubscriptions,
  updateNotificationSubscription,
  getNotificationSettings,
  updateNotificationSettings,
  getNotifications,
};

export default api;
