import { FaSpinner, FaTimes, FaUser } from 'react-icons/fa';
import GenericDialog from '../shared/GenericDialog';
import { useAuth } from '../../hooks/useAuth';
import api from '../../api';
import { toast } from 'react-toastify';
import axios from 'axios';
import { isEmail } from '../../utils';
import { FormikErrors, useFormik } from 'formik';
import Input from '../shared/Input';
import { globalThemeStyles } from '../../config/globalStyles';
import { useState } from 'react';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import useDialog from '../../hooks/useDialog';
import { useNavigate } from 'react-router-dom';
interface Props {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

interface FormValues {
  firstname: string;
  lastname: string;
  email: string;
}

export function ProfileDialog({ isOpen, setIsOpen }: Props) {
  const navigate = useNavigate();
  const { user, setUser, logout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [isConfirmation, setIsConfirmation] = useState(false);
  const [isRequestSent, setIsRequestSent] = useState(false);

  // delete dialog
  const { closeDialog, isDialogOpened, openDialog } = useDialog();

  const close = () => {
    setIsOpen(false);
  };

  const closeDialogWithConfirmation = () => {
    if (
      user?.email !== formik.values.email ||
      user?.firstname !== formik.values.firstname ||
      user?.lastname !== formik.values.lastname
    ) {
      return setIsConfirmation(true);
    }
    close();
  };

  async function deleteUser() {
    if (!user) return;
    try {
      const response = await api.user.deleteUser(user.id);
      if (response.status === 204) {
        toast.success('User deleted successfully!');
        closeDialog();
        close();
        logout();
        navigate('/login');
      } else {
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  async function triggerDataRequest() {
    if (!user) return;
    try {
      const response = await api.user.triggerDataRequest(user.id);
      if (response.status === 200) {
        toast.success('Data request sent successfully!');
        setIsRequestSent(true);
      } else {
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        if (e.response?.data) {
          toast.error(e.response?.data.errorMessage);
        }
      } else {
        console.log(e);
        toast.error(
          'Something really went wrong, you might want to contact support!'
        );
      }
    }
  }

  const formik = useFormik<FormValues>({
    enableReinitialize: true,
    initialValues: {
      firstname: user?.firstname || '',
      lastname: user?.lastname || '',
      email: user?.email || '',
    },
    validate: (values) => {
      const errors: FormikErrors<FormValues> = {};

      if (!values.firstname) {
        errors.firstname = 'Required';
      }

      if (!values.email) {
        errors.firstname = 'Required';
      }

      if (values.email && !isEmail(values.email)) {
        errors.firstname = 'Email is invalid';
      }

      return errors;
    },
    onSubmit: (values) => {
      if (!user) return;
      setIsLoading(true);
      api.user
        .updateUser(user.id, values)
        .then((res) => {
          setUser((u) => ({
            ...u!,
            firstname: res.data.result!.firstname,
            lastname: res.data.result!.lastname,
            email: res.data.result!.email,
          }));
          toast.success('User Information updated successfully');
          setIsLoading(false);
          close();
        })
        .catch((e) => {
          setIsLoading(false);
          if (axios.isAxiosError(e)) {
            if (e.response?.data) {
              toast.error(e.response?.data.errorMessage);
            }
          } else {
            console.log(e);
            toast.error(
              'Something really went wrong, you might want to contact support!'
            );
          }
        });
    },
  });

  return (
    <>
      <GenericDialog
        onUpperRightClick={closeDialogWithConfirmation}
        upperRightIcon={
          <FaTimes size={20} className='text-gray-600 dark:text-gray-300' />
        }
        header={isConfirmation ? 'Confirmation' : 'Profile'}
        headerIcon={isConfirmation ? null : <FaUser size={20} />}
        isOpen={isOpen}
        setIsOpen={(v: boolean) => {
          if (v === false) {
            return closeDialogWithConfirmation();
          }
          setIsOpen(v);
        }}
        footer={<></>}
      >
        {isConfirmation ? (
          <div className='pl-2'>
            <div className='mt-5 space-y-6 text-slate-700 dark:text-slate-200'>
              You have unsaved changes. Are you sure you want to leave?
            </div>
            <div className='mt-4 flex justify-end space-x-4'>
              <button
                className=' rounded p-2 px-4 font-semibold text-slate-600 hover:bg-slate-200 disabled:opacity-50 dark:text-slate-300 dark:hover:bg-slate-700'
                disabled={isLoading}
                onClick={() => {
                  setIsConfirmation(false);
                }}
              >
                Cancel
              </button>
              <button
                className='flex items-center gap-x-2 rounded bg-rose-600 p-2 px-4 font-semibold text-white hover:bg-rose-700 disabled:opacity-50'
                disabled={isLoading}
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                {isLoading && <FaSpinner size={16} className='animate-spin' />}
                <span>OK</span>
              </button>
            </div>
          </div>
        ) : (
          <div className='space-y-6'>
            <div>
              <Input
                label='User Name'
                error={!!formik.errors.firstname && formik.touched.firstname}
                onChange={formik.handleChange}
                value={formik.values.firstname}
                type='text'
                name='firstname'
                id='first-name'
                className={globalThemeStyles.inputbox}
              />
            </div>
            <div>
              <Input
                label='Email'
                error={!!formik.errors.email && formik.touched.email}
                onChange={formik.handleChange}
                value={formik.values.email}
                type='text'
                name='email'
                id='email'
                className={globalThemeStyles.inputbox}
              />
            </div>
            <div className='text-slate-900 dark:text-slate-50'>
              <div className='text-lg font-semibold text-slate-900 dark:text-slate-50'>
                Request Personal Data
              </div>
              <div className='mt-2'>
                By clicking the button below, you can request a comprehensive
                copy of all personal data we have collected and stored about
                you.
              </div>
              <div className='mt-1'>
                This includes information such as your account details,
                user-generated content, and any other data associated with your
                use of our services.
              </div>
              <div className='mt-4 flex items-center space-x-4'>
                <button
                  onClick={triggerDataRequest}
                  disabled={isRequestSent}
                  className='flex items-center space-x-2 rounded bg-blue-600 px-5 py-1 font-semibold text-white transition-colors hover:bg-blue-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
                >
                  {isRequestSent ? 'Requested' : 'Request data'}
                </button>
                {isRequestSent && (
                  <div className='text-slate-400'>Email has been sent!</div>
                )}
              </div>
              <div className='mt-8 text-lg font-semibold text-slate-900 dark:text-slate-50'>
                Delete Account
              </div>
              <div className='mt-2'>
                By proceeding with the deletion of your account, please be aware
                that all your data will be permanently erased from our systems.
              </div>
              <div className='mt-1'>
                This action is irreversible and includes the deletion of all
                personal information, user-generated content, and any other data
                associated with your account.
              </div>

              <button
                onClick={openDialog}
                className='mt-4 flex items-center space-x-2 rounded bg-red-600 px-5 py-1 font-semibold text-white transition-colors hover:bg-red-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
              >
                Delete
              </button>
            </div>

            <div className='mt-4 flex w-full items-center justify-end'>
              <div className='flex items-center justify-end space-x-4'>
                <button
                  className=' rounded p-1 px-4 font-semibold text-slate-600 transition-colors hover:bg-slate-200 disabled:opacity-50 dark:text-slate-300 dark:hover:bg-slate-700'
                  onClick={closeDialogWithConfirmation}
                  disabled={isLoading}
                >
                  Cancel
                </button>
                <button
                  disabled={isLoading}
                  onClick={formik.submitForm}
                  className='flex items-center space-x-2 rounded bg-emerald-600 px-5 py-1 font-semibold text-white transition-colors hover:bg-emerald-800 disabled:bg-slate-300 disabled:text-slate-200 dark:disabled:bg-slate-600 dark:disabled:text-slate-700'
                >
                  {isLoading && (
                    <FaSpinner size={16} className='animate-spin' />
                  )}
                  <div>Save</div>
                </button>
              </div>
            </div>
            <DeleteConfirmationDialog
              isLoading={false}
              isOpen={isDialogOpened}
              title={`Delete account `}
              message={
                <>
                  Do you really want to delete your user account? This action is
                  irreversible.
                </>
              }
              close={closeDialog}
              onSubmit={deleteUser}
            />
          </div>
        )}
      </GenericDialog>
    </>
  );
}
