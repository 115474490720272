import { useState } from 'react';
import { ITag } from '../api/services/tag.service';
import { TagCategory } from '../types';

export const tagCategories: TagCategory[] = [
  { name: 'Moods', backendName: 'MOOD', maxTags: 10 },
  { name: 'Styles', backendName: 'STYLE', maxTags: 5 },
  { name: 'Music For', backendName: 'MUSICFOR', maxTags: 10 },
  { name: 'Instruments', backendName: 'INSTRUMENT', maxTags: 10 },
  { name: 'Elements', backendName: 'ELEMENT', maxTags: 10 },
  { name: 'Keywords', backendName: 'KEYWORD', maxTags: 10 },
];

export const useTags = () => {
  const [allTags, setAllTags] = useState<ITag[]>([]);
  const [activeTagCategory, setActiveTagCategory] = useState<TagCategory>(
    tagCategories[0]
  );
  const [activeTags, setActiveTags] = useState<ITag[]>([]);

  return {
    activeTagCategory,
    setActiveTagCategory,
    activeTags,
    setActiveTags,
    allTags,
    setAllTags,
  };
};
