import axios from 'axios';
import { useEffect, useMemo, useRef, useState } from 'react';
import { BiAlbum } from 'react-icons/bi';
// import { FaTrash } from 'react-icons/fa';
import { toast } from 'react-toastify';
import useDialog from '../../hooks/useDialog';
import { useAuth } from '../../hooks/useAuth';
import api from '../../api';
import { IFile } from '../../api/services/file.service';
import { classNames } from '../../utils';
import { HiOutlinePencilSquare } from 'react-icons/hi2';
import { FaUpload } from 'react-icons/fa';
import DeleteConfirmationDialog from '../shared/DeleteConfirmationDialog';
import { mapAppEnvironment } from '../global/Navbar';

export default function TeamArtworkUploader({
  className,
  height = 20,
  width = 20,
}: {
  className?: string;
  height?: number;
  width?: number;
}) {
  const { activeTeam, updateUser } = useAuth();
  const ref = useRef<HTMLInputElement>(null);
  const [url, setUrl] = useState<string | undefined>();
  const [isDeletingAlbum, setIsDeletingAlbum] = useState(false);
  const { isDialogOpened, closeDialog } = useDialog();

  useEffect(() => {
    if (activeTeam?.landingImageFileId) {
      const appEnv = mapAppEnvironment(window.location.host);
      setUrl(
        `https://${appEnv}-ktr-assets.s3.nl-ams.scw.cloud/${activeTeam?.landingImageFileId}`
      );
    }
  }, [activeTeam]);

  const confirmDeleteImage = async () => {
    if (!activeTeam?.landingImageFileId) return;
    setIsDeletingAlbum(true);
    await api.file.deleteFile(activeTeam?.landingImageFileId);
    setUrl(undefined);
    updateUser();
    closeDialog();
    setIsDeletingAlbum(false);
  };

  async function upload(file: File, url: string) {
    return await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  }

  async function updateFile(file: IFile) {
    const { data: fileResponse } = await api.file.updateFilePartially(file.id, {
      status: 'READY',
    });
    return fileResponse.result;
  }

  return (
    <>
      <label
        className={`relative block h-${height} w-${width} flex-none cursor-pointer items-center justify-center overflow-hidden rounded-lg border border-slate-300 bg-white object-cover text-center font-semibold text-white ring-1 ring-slate-900/10 disabled:bg-slate-300 disabled:text-slate-200 dark:bg-slate-900 dark:disabled:bg-slate-600 dark:disabled:text-slate-700`}
      >
        <div
          className={classNames(
            'group absolute top-0 left-0 flex h-full w-full rounded-lg bg-[rgba(0,0,0,.3)] opacity-0 transition-colors hover:opacity-100',
            url ? 'bg-[rgba(0,0,0,.3)]' : ' bg-white dark:bg-slate-900'
          )}
        >
          {url ? (
            <HiOutlinePencilSquare className='m-auto h-8 w-8 scale-50 transition-all group-hover:scale-100' />
          ) : (
            <FaUpload className='m-auto h-8 w-8 scale-50 text-slate-900 transition-all group-hover:scale-100 dark:text-white' />
          )}
        </div>
        {url ? (
          <img src={url} alt='artwork' className={`w-${width} object-cover`} />
        ) : (
          <BiAlbum className='m-auto h-20 w-20 text-slate-900 dark:text-white' />
        )}
        <input
          data-testid='artwork-input'
          multiple={false}
          ref={ref}
          className='hidden'
          type='file'
          accept='.jpg,.png'
          onChange={async (e) => {
            const file = e.target.files?.[0];

            if (ref.current) {
              ref.current.value = '';
            }
            if (activeTeam === null) {
              return;
            }

            if (file) {
              if (file.type && !file.type.startsWith('image/')) {
                toast.error('File is not an image.');
              } else {
                if (activeTeam?.landingImageFileId) {
                  await api.file.deleteFile(activeTeam?.landingImageFileId);
                }
                const {
                  data: { result: fileObj },
                } = await api.file.addFile({
                  name: file.name,
                  size: file.size,
                  contentType: file.type,
                  tenantId: activeTeam.id,
                  role: 'ARTWORK',
                  publicAccess: true,
                });
                const result = await api.file.getFilePresignedUploadUrl(
                  fileObj.id
                );
                const url = result.data.result?.presignedUrl;
                // blocks until file uploaded successfully
                await upload(file, url);
                // set file status to READY
                await updateFile(fileObj);

                await api.team.updateTeam(
                  activeTeam.id,
                  Object.assign({}, activeTeam, {
                    landingImageFileId: fileObj.id,
                  })
                );

                updateUser();
              }
            }
          }}
        />
      </label>
      <DeleteConfirmationDialog
        isLoading={isDeletingAlbum}
        isOpen={isDialogOpened}
        title={`Delete Artwork`}
        message={
          <>
            Do you really want to delete{' '}
            <span className='font-semibold'>"Album artwork"</span> ?
          </>
        }
        close={closeDialog}
        onSubmit={confirmDeleteImage}
      />
    </>
  );
}
